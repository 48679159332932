import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { UserStatus } from '../user';

export const ACTIVE_STATUSES: UserStatus[] = ['PAID', 'TRIAL', 'GRACEPERIOD'];

export const needStatusGuard =
  (...statuses: UserStatus[]): CanActivateFn =>
  () => {
    const router = inject(Router);
    const userHasStatus = inject(AuthService).currentUserHasStatus(...statuses);
    return userHasStatus || router.parseUrl('/compte');
  };

export const needActiveStatusGuard = needStatusGuard(...ACTIVE_STATUSES);
